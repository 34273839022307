/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
@import './styles/Custom.scss';
@import './styles/Colors.scss';
@import './styles/App.scss';
@import './styles/GridItem.scss';
@import './styles/ListItem.scss';
@import './styles/OrderForm.scss';

html, body {
  background-color: $brg-gray-light !important;
  margin: 0px;
  padding-top: 0px;
}
* {
  font-family: "Calibri Light";
}
#root {
  background-color: $brg-gray-light;
  color: $gray-dark;
}
.container {
  padding-bottom: 80px;
}