@import './Custom.scss';

$padding-horizontal: 90px;
$padding-horizontal-mobile: 30px;

.orderForm {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 20px $padding-horizontal;
    padding-bottom: 40px;
    border: 0.5px $brg-gray solid;
    position: relative;

    .icon {
        color: $orange-bright;
        width: 100%;
        height: 40px;
        position: absolute;
        top: -40px;
        margin-left: calc(50% - 400px);
        text-align: center;
        box-sizing: content-box;
        margin-left: -$padding-horizontal;

        .heading {
            background-color: $brg-gray-light;
            width: 420px;
            text-align: center;
            white-space: nowrap;
            padding: 0px 30px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .illustration {
        width: 400px;
        height: intrinsic;
        // position: absolute;
    }

    button {
        width: 200px;
        display: flex;
        justify-content: center;
    }

    .form {
        margin: 0px;
        margin: 10px 0px;
        padding: 0px;

        .btnOrder {
            border: 1px $gray-light solid;
            color: $gray-light;
            cursor: not-allowed;
        }

        .btnOrder.active {
            cursor: pointer;
            color: $orange-bright;
            border: 1px $orange-bright solid;

            &:hover {
                color: $orange-bright;
                border: 1px $orange-bright solid;
            }

            &:active {
                color: $orange-bright;
                border: 1px $orange-bright solid;
            }
        }

        button:focus {
            outline: 0 !important;
        }

        .dropdown {
            border-radius: 0px;
        }

        .dropdown .icon {
            color: $orange-bright;
            background-color: transparent;
        }


        .orderLoading {
            margin-right: 10px;
        }
    }

    .overview {
        flex-direction: column;

        .col {
            display: flex;
            justify-content: flex-end;
        }

        .info {
            color: $gray;

            & p {
                padding-top: 10px;
                border-top: 1px $gray-light solid;
            }
        }

        button {
            border: 1px $gray-light solid;
            color: $gray-light;

            // border: none;
            // padding-right: 0px;
            // color: $gray;
            img {
                margin-left: 5px;
                height: 25px;
            }

            .text {
                width: 176px;

            }

            &.active {
                border: 1px $orange-bright solid;
                color: $orange-bright;

                img {

                    stroke: $orange-bright !important;
                }
            }

            &.expand {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                padding-bottom: 2rem;

                .text {
                    margin-bottom: 2rem;
                }

                .cartList {
                    margin-right: 2rem;

                    p,
                    td {
                        color: $gray;
                        text-align: end;
                        margin-right: 1rem;
                        height: 10px;
                    }
                }
            }

        }
    }

}

@media screen and (max-width: 768px) {
    .orderForm {
        padding: 20px $padding-horizontal-mobile;
        .icon {
            height: 38px;
            top: -38px;
            margin-left: -$padding-horizontal-mobile;
            .heading {
                font-size: 28px;
                width: 330px;
                padding: 0px 15px;
            }
        }

        .form .btnOrder {
                margin-top: 10px;
        }
    }
}