/* -------------------------------------------------------------------------- */
/* Colors */
/* -------------------------------------------------------------------------- */
$gray-dark: #575757;
$gray-light: #d0d0d0;
$gray: #b2b2b2;

$brg-gray-dark: #c4c1bd;
$brg-gray: #dad7d2;
$brg-gray-light: #f0eee9;

$orange-grayish: #C4c1bd;
$gray-light-orangish: #DAD7D2;
$orange-bright: #fb763e;
$orange-pale: #ffcb9c;
$orange-light-grayish: #fde4d0;

$white: #fff;
$white-pale: rgba(255, 255, 255, 0.6);
$white-light: rgba(255, 255, 255, 0.3);
