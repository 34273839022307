@import './Custom.scss';


.container {
    padding-top: 30px;
}

h1.heading {
    font-family: 'Calibri Light';
    text-align: center;
    color: $orange-bright;
    margin-top: 20px;
    margin-bottom: 25px;
}

.search {
    align-self: center;
}

.navigation {
    margin-bottom: 15px;
    margin-left: 0px !important;
    margin-right: 0px !important;
    a {
        &:hover {
            text-decoration: underline $gray-light;
        }
        .category {
            font-size: 24px;
            font-family: 'Calibri Light';
            color: #bbb;
            padding-right: 15px;
        }
    }
    
    a.active {
        &:hover {
            text-decoration: underline $gray-dark;
        }
        .category {
            font-family: 'Calibri Bold';
            color: #555;
        }
    }
}

.displayOption {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
    button {
        border: none;
        color: #bbb;
    }
    button.active {
        color: $orange-bright;
    }
}

a.disable {
    pointer-events: none;
    cursor: default;
}


.searchInput {
    width: fit-content;
    position: relative;
    display: flex;
    align-items: center;
    // margin-bottom: 15px;
    input {
        width: 230px;
        height: 36px;
        border: 1px solid rgba(34, 36, 38, 0.15);
        color: $gray-dark;
        padding: .67857143em 2.1em .67857143em 1em;
        &::placeholder {
            color: $gray-light;
            font-weight: lighter;
        }
    }
    svg {
        color: $gray-dark;
        right: 10px;
        position: absolute;
    }
}

.tooltip {
    .tooltip-inner {
        background: $brg-gray-dark !important;
        color: #575756;
    }
    .arrow::before {
        border-top-color: $brg-gray-dark !important;
    }
}

.itemListContainer {
    margin: 0px -10px !important;
}
.item {
    padding: 0px !important;
    // margin-bottom: 10px;
    
    .amount {
        &.inReview {
            span {
                color: $gray-light;
            }
        }

     &.promoproduct {
            button {
                cursor: not-allowed;
            }
            pointer-events: none;
            opacity: 0.35;
     }
    }
    .amount span {
        margin: 0px 5px;
        width: 15px;
        text-align: center;
        font-size: 17px;
    }

    .amount .btnAmount {
        background-color: unset;
        height: 25px;
        display: flex;
        &:disabled {
            svg {
                color: $gray-light;
            }
        }
    }

    .amount .btnAmount svg {
        color: $gray-dark;
        pointer-events: none;
        height: 18px;
        width: 18px;
    }

    .btnAmount {
        border-radius: 20px;
        border: none;
        padding: 0px;
        justify-content: center;
        align-items: center;
    }
    
    .factsheetCode {
        padding: 5px 10px;
        border-radius: 19px;
        color: #fff;
        white-space: nowrap;
        &.IN {
            background-color: #EA5B51;
        }
        // &.IS {
        //     background-color: #648a83;
        // }
        &.PB, &.IS {
            background-color: #0089bb;
        }
        &.RC {
            background-color: #db763e;
        }
        &.WA {
            background-color: #f3b927;
        }
        &.FO {
            background-color: #786e67;
        }
    }

    @media screen and (max-width: 768px) { 
        .amount {
            margin: 5px 0px;
        }
    }
}

.thankYouScreen {
    height: 90vh;
    margin-top: 100px;
    button {
        margin: auto;
        margin-top: 20px;
    }
}