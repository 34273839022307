@import './Colors.scss';

button {
    text-transform: uppercase !important;
    line-height: 1.33337;
    font-size: 17px;
    min-width: 23px;
    padding: 6px 12px;
    display: flex;
    background-color: transparent;
    font-family: 'Cambria' !important;
    height: max-content;
    border: 1px $orange-bright solid;
    color: $orange-bright;
}

span.bold {
    font-family: 'Calibri Bold';
}

.h-min-content {
    height: min-content !important;
}

@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0) { 
    .col {
        flex-basis: auto !important;
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .col {
        flex-basis: auto !important;
    }
 }