@import './Custom.scss';

.listItem {
    background-color: #fff;
    margin-bottom: 10px;
    margin-left: 10px !important;
    margin-right: 10px !important;
    padding: 5px 0px;
    align-items: center;
    &.active {
        background-color: $orange-light-grayish;
    }
    &:hover {
        outline: 1px solid $orange-bright;
        // cursor: pointer;
    }
    img {
        width: 100%;
        &.inReview {
            filter: blur(2px);
            -ms-filter: blur(2px); 
            filter:progid:DXImageTransform.Microsoft.Blur(pixelradius='2', shadowopacity='0.0');
        }
    }
    .review-label {
        font-family: 'Calibri Bold';
        color: #ea5b51;
    }
}