@import './Custom.scss';


.gridItem {
    background-color: #fff;
    height: 100%;
    padding: 20px;
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    border: 10px solid #f0eee9;
    
    &.active,  &.active:hover{
        background-color: $orange-pale;
    }
    &:hover {
        background-color: $orange-light-grayish;
        // box-sizing: border-box;
        // border: 1px solid $orange-bright;
        // cursor: pointer;
        // animation-name: focus;
        // animation-duration: 1s;
    }
    @-webkit-keyframes focus {
        from {height: 100%;}
        to {height: 105%;}
    }
    .image {
        position: relative;
        .inReview {
            filter: blur(1px);
        }
        .thumbnailOverlay {
            opacity: 0;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                width: 40px;
                height: auto;
            }
            svg:hover {
                cursor: pointer;
            }
            .btn {
                color: $orange-bright;
                border: none;
            }
        }
        &:hover {
            img:not(.promoproduct) {
                filter: blur(1px);
            }
            .thumbnailOverlay {
                opacity: 1;
            }
        }   
        
    }

    .text {
        flex: 1;
      }
      
    .text .name {
        font-family: 'Calibri Bold';
        color: #555;
    }
    
    .text .additional {
        color: #8a8a8a;
        // font-family: 'Calibri Bold';
    }

    .checkbox {
        margin: 0px 6px;
    }

    .review-label {
        position: absolute;
        font-family: 'Calibri Bold';
        color: #ea5b51;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 50%;
        height: 20%;
        margin: auto;
        text-align: center;
        z-index: 1;
    }
}
